/* stylelint-disable */
/* 
 * Tailwind CSS directives - IDE may show warnings for these,
 * but they are valid and will be processed by the Tailwind compiler
 */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;
    --primary: 0 72.2% 50.6%;
    --primary-foreground: 0 85.7% 97.3%;
    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;
    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;
    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 72.2% 50.6%;
    --radius: 0.5rem;
  }
 
  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;
    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 72.2% 50.6%;
    --primary-foreground: 0 85.7% 97.3%;
    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;
    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 72.2% 50.6%;
  }

  html {
    font-family: var(--font-founders-grotesk), system-ui, sans-serif;
  }
  body {
    @apply bg-sourced-white text-sourced-black;
  }
}

/* Custom utility classes for brand-specific styling */
.bg-sourced-primary {
  @apply bg-sourced-pink;
}

.text-sourced-primary {
  @apply text-sourced-black;
}

.border-sourced-primary {
  @apply border-sourced-black;
}

/* Mobile-specific styles for tabs */
@media (max-width: 767px) {
  /* Enhance tab visibility on mobile */
  [role="tablist"] {
    @apply border border-gray-200 rounded-md overflow-hidden;
    -webkit-tap-highlight-color: transparent;
  }
  
  [role="tab"] {
    @apply text-sm py-3 font-medium;
    appearance: none;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }
  
  [role="tab"][data-state="active"] {
    @apply bg-sourced-white text-sourced-black font-semibold shadow-sm;
  }
  
  /* Improve touch targets for mobile */
  button, 
  [role="button"],
  a {
    @apply touch-manipulation;
  }
}
